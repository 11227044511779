<footer class="footer-container text-center text-md-left pt-4">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4 mb-3 justify-content-center">
        <svg class="logo footer-logo"></svg>
      </div>
      <div class="col-12 col-md-8 mb-4">
        <ul class="list-unstyled d-md-flex flex-wrap">
          <li class="mb-3 mb-md-4 mr-md-5" [hidden]="hideContractualSummary">
            <a [routerLink]="['/contractualSummary' | localize]"
              data-test-id="footer-term-n-cond-link">{{'Validity_Plan_Contract_Summary' | translate}}</a>
          </li>
          <li class="mb-3 mb-md-4 mr-md-5">
            <a [routerLink]="['/termsAndConditions' | localize]"
              data-test-id="footer-term-n-cond-link">{{'Footer_TermAndCond' | translate}}</a>
          </li>
          <li class="mb-3 mb-md-4 mr-md-5">
            <a [routerLink]="['/privacyNotice' | localize]"
              data-test-id="footer-privacy-notice-link">{{'Privacy_Notice_Title_Text' | translate}}</a>
          </li>
          <li class="mb-3 mb-md-4 mr-md-5">
            <a [routerLink]="['/aboutCubic' | localize]" data-test-id="footer-about-cubic-link"
              [innerHTML]="'About_Cubic_Title_Text' | translate"
              [attr.aria-label]="'About_Cubic_Title_Text' | translate">
            </a>
          </li>

          <li class="mb-3 mb-md-4 mr-md-5">
            <a [routerLink]="['/contactUs' | localize]" data-test-id="footer-contact-us-link">{{'Contact_Us_Title_Text'
              | translate}}</a>
          </li>
          <li>
            <div class="d-flex justify-content-center">
              <a class="mr-3" href="https://linkedin.com/company/cubic3connectivity" target="_blank" title="Linkedin">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.625 0H4.375C1.95913 0 0 1.95913 0 4.375V16.625C0 19.0409 1.95913 21 4.375 21H16.625C19.0417 21 21 19.0409 21 16.625V4.375C21 1.95913 19.0417 0 16.625 0ZM7 16.625H4.375V7H7V16.625ZM5.6875 5.8905C4.84225 5.8905 4.15625 5.19925 4.15625 4.347C4.15625 3.49475 4.84225 2.8035 5.6875 2.8035C6.53275 2.8035 7.21875 3.49475 7.21875 4.347C7.21875 5.19925 6.53363 5.8905 5.6875 5.8905ZM17.5 16.625H14.875V11.7215C14.875 8.7745 11.375 8.99763 11.375 11.7215V16.625H8.75V7H11.375V8.54438C12.5965 6.28163 17.5 6.1145 17.5 10.7109V16.625Z"
                    fill="currentColor" />
                </svg>
              </a>

              <a class="mr-3" href="https://x.com/Cubic3connectivity" target="_blank" title="X">
                <svg viewBox="506.089 174.168 21 21" width="21" height="21" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 506.089 184.668 C 506.089 178.869 510.79 174.168 516.589 174.168 C 522.388 174.168 527.089 178.869 527.089 184.668 C 527.089 190.467 522.388 195.168 516.589 195.168 C 510.79 195.168 506.089 190.467 506.089 184.668 Z M 510.121 178.584 L 515.14 185.295 L 510.089 190.751 L 511.226 190.751 L 515.648 185.974 L 519.221 190.751 L 523.089 190.751 L 517.787 183.663 L 522.488 178.584 L 521.352 178.584 L 517.28 182.984 L 513.989 178.584 L 510.121 178.584 Z M 511.793 179.422 L 513.57 179.422 L 521.417 189.914 L 519.64 189.914 L 511.793 179.422 Z"
                    fill="currentColor" />
                </svg>
              </a>

              <a href="https://www.youtube.com/channel/UC04lK6me7J4_CdlFnKkuopw" title="Youtube" target="_blank">
                <svg viewBox="447.954 179.164 24 16.8" width="24" height="16.8" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 471.954 187.564 C 471.954 187.564 471.954 191.469 471.452 193.34 C 471.176 194.373 470.363 195.186 469.33 195.462 C 467.459 195.964 459.954 195.964 459.954 195.964 C 459.954 195.964 452.449 195.964 450.577 195.462 C 449.545 195.186 448.731 194.373 448.455 193.34 C 447.954 191.469 447.954 187.564 447.954 187.564 C 447.954 187.564 447.954 183.659 448.455 181.787 C 448.731 180.755 449.545 179.942 450.577 179.666 C 452.449 179.164 459.954 179.164 459.954 179.164 C 459.954 179.164 467.459 179.164 469.33 179.666 C 470.363 179.942 471.176 180.755 471.452 181.787 C 471.954 183.659 471.954 187.564 471.954 187.564 Z M 457.554 191.164 L 463.789 187.564 L 457.554 183.964 L 457.554 191.164 Z"
                    fill="currentColor" />
                </svg>
              </a>
            </div>
          </li>
        </ul>
        <p class="m-0 text-center text-md-left" data-test-id="footer-about-cubic-rights">
          ©{{ today | date:'yyyy' }}
          <span [innerHTML]="'Footer_AllRightsReserved' | translate"></span>
        </p>
      </div>
    </div>
  </div>
</footer>