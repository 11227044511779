<div class="container">
  <div class="impressum">
    <hr>
    <div class="row justify-content-left">
      <div>
        <h2 class="impressum-title" data-test-id="impressum-title-text">
          {{'Impressum_Title_Text' | translate }}</h2>
      </div>
    </div>
    <div class="row justify-content-left">
      <div class="col-12">
        <p data-test-id="impressum-description-text" class="col-12"
          [innerHTML]="'Impressum_Description_Text' | translate"
          [attr.aria-label]="'Impressum_Description_Text' | translate">
        </p>
      </div>
      <div class="col-12">
        <b data-test-id="impressum-cubic-telecom-hq-title" class="col-12 text-left"
          [innerHTML]="'Impressum_Cubic_Telecom_HQ_Title' | translate"
          [attr.aria-label]="'Impressum_Cubic_Telecom_HQ_Title' | translate">
        </b>
        <p data-test-id="impressum-cubic-telecom-hq-text1" class="col-6 col-lg-2 address-text"> Cubic<span
            class='company-brand-evelated'>3</span></p>
        <p data-test-id="impressum-cubic-telecom-hq-text2" class="col-6 col-lg-2 address-text"> The Hive</p>
        <p data-test-id="impressum-cubic-telecom-hq-text3" class="col-6 col-lg-2 address-text"> Carmanhall Road </p>
        <p data-test-id="impressum-cubic-telecom-hq-text4" class="col-12 col-lg-4 address-text">Sandyford</p>
        <p data-test-id="impressum-cubic-telecom-hq-text5" class="col-6 col-lg-2 address-text">Dublin 18</p>
        <p data-test-id="impressum-cubic-telecom-hq-text5" class="col-6 col-lg-2 address-text">D18Y2C9</p>
        <p data-test-id="impressum-cubic-telecom-hq-text6" class="col-4 col-lg-2"> Ireland</p>
      </div>
      <div class="col-12">
        <b data-test-id="impressum-registered-address-title" class="col-12 text-left">
          {{'Impressum_Registered_Address_Title' | translate }}
        </b>
        <p data-test-id="impressum-registered-address-text1" class="col-9 col-lg-2 address-text">
          Cubic<span class='company-brand-evelated'>3</span>.</p>
        <p data-test-id="impressum-registered-address-text2" class="col-lg-2 address-text">Arthur Cox Building</p>
        <p data-test-id="impressum-registered-address-text3" class="col-lg-2 address-text">Earlsfort Terrace</p>
        <p data-test-id="impressum-registered-address-text4" class="col-6 col-lg-2 address-text">Dublin 2</p>
        <p data-test-id="impressum-registered-address-text5" class="col-lg-2 address-text">D02T380</p>
        <p data-test-id="impressum-registered-address-text6" class="col-lg-2">Ireland</p>
      </div>
      <div class="col-12">
        <div class="col-12">
          <b data-test-id="impressum-company-number-title" class="text-left">
            {{'Impressum_Company_Number_Title' | translate }}
          </b>
          <span data-test-id="impressum-company-number-text" class="text-left">
            {{'Impressum_Company_Number_Text' | translate }}
          </span>
        </div>
        <br>
      </div>
      <div class="impressum-text col-12">
        <b data-test-id="impressum-vat-number-title" class="text-left">
          {{'Impressum_VAT_Number_Title' | translate }}
        </b>
        <span data-test-id="impressum-vat-number-text" class="text-left">
          {{'Impressum_VAT_Number_Text' | translate }}
        </span>
      </div>
      <div class="impressum-text col-12">
        <b data-test-id="impressum-phone-number-title" class="text-left">{{'Impressum_Phone_Number_Title' | translate
          }} </b>
        <span data-test-id="impressum-phone-number-text" class="text-left">{{'Impressum_Phone_Number_Text' | translate
          }}</span>
      </div>
      <div class="impressum-text col-12">
        <b data-test-id="impressum-email-title" class="text-left">{{'Email' | translate }}</b>:
        <span data-test-id="impressum-email-text" class="text-left">
          {{'Impressum_Email_Text' | translate }}</span>
      </div>
      <div class="impressum-text col-12">
        <b data-test-id="impressum-chief-executive-officer-title" class="text-left">
          {{'Impressum_Chief_Executive_Officer_Title' | translate }}
        </b>
        <span data-test-id="impressum-chief-executive-officer-text" class="text-left">
          {{'Impressum_Chief_Executive_Officer_Text' | translate }}
        </span>
      </div>
      <div class="impressum-text col-12">
        <b data-test-id="impressum-online-dispute-resolution-title">
          {{'Impressum_Online_Dispute_Resolution_Title' | translate }} </b>
        <span data-test-id="impressum-online-dispute-resolution-txt">{{'Impressum_Online_Dispute_Resolution_Text' |
          translate }}</span>
      </div>
      <div class="col-12">
        <div class="col-12">
          <b data-test-id="impressum-supervisory-authority-title">
            {{'Impressum_Supervisory_Authority_Title' | translate }}</b>
          <span data-test-id="impressum-supervisory-authority-txt">
            {{'Impressum_Supervisory_Authority_Text' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>