<app-card-page-header class="keep-sticky"></app-card-page-header>
<div class="product-checkout-container" data-test-id="product-checkout">
  <div class="d-none d-md-block page-header">{{'Product_Checkout' | translate}}</div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-7">
        <p class="title" data-test-id="product-checkout-payment-title">
          <strong>{{'Product_Checkout_Payment_Title' | translate}}</strong>
        </p>
        <br>
        <div *ngIf="showWarning" class="warning-banner">
          <div class="alert alert-warning" role="alert">
            <div class="alert-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.2571 5.099C11.0221 3.739 12.9791 3.739 13.7431 5.099L19.3231 15.019C20.0731 16.353 19.1101 17.999 17.5811 17.999H6.42009C4.89009 17.999 3.92709 16.353 4.67709 15.019L10.2571 5.099ZM13.0001 15C13.0001 15.2652 12.8947 15.5196 12.7072 15.7071C12.5197 15.8946 12.2653 16 12.0001 16C11.7349 16 11.4805 15.8946 11.293 15.7071C11.1054 15.5196 11.0001 15.2652 11.0001 15C11.0001 14.7348 11.1054 14.4804 11.293 14.2929C11.4805 14.1054 11.7349 14 12.0001 14C12.2653 14 12.5197 14.1054 12.7072 14.2929C12.8947 14.4804 13.0001 14.7348 13.0001 15V15ZM12.0001 7C11.7349 7 11.4805 7.10536 11.293 7.29289C11.1054 7.48043 11.0001 7.73478 11.0001 8V11C11.0001 11.2652 11.1054 11.5196 11.293 11.7071C11.4805 11.8946 11.7349 12 12.0001 12C12.2653 12 12.5197 11.8946 12.7072 11.7071C12.8947 11.5196 13.0001 11.2652 13.0001 11V8C13.0001 7.73478 12.8947 7.48043 12.7072 7.29289C12.5197 7.10536 12.2653 7 12.0001 7Z"
                  fill="#AB8A13" />
              </svg>
            </div>
            {{'Global_Payments_Disclaimer' | translate}}
            <button type=" button" (click)="closeDisclaimer()" class="close close-icon " data-bs-dismiss="alert"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="iframe-container" [class.d-none]="!showIframeContainer">
          <iframe id="payIframeId" class="product-checkout-iframe" [style.height.px]="iframeHeight"
            scrolling="no"></iframe>
        </div>
        <button #launchHppBtn id="payButtonId" class="d-none"
          data-test-id="product-checkout-btn-continue">{{'Home_Plans_BuyNow' | translate}}</button>
        <div class="sca-warning-box" [class.d-none]="!showIframeContainer">
          <span class="info-icon">
            <span class="info-icon text-primary">
              <svg fill="currentColor" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 416.979 416.979"
                xml:space="preserve">
                <g>
                  <path
                    d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85
      c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786
      c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576
      c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765
      c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z" />
                </g>
              </svg>
            </span>
          </span>
          <span [innerHTML]="'SCA_Warning_Text' | translate"></span>
        </div>
      </div>
      <div *ngIf="showOrderSummary" class="col-sm-12 col-md-6 col-lg-5 order-summary">
        <!-- Enable In-car topup -->
        <div *ngIf="showInCarTopup() && showEnableInCarTopup && !isGen4Vehicle">
          <p class="title">
            <strong data-test-id="In-Car-TopUp-TitleText">{{'Incar_TopUp_Title' | translate}}</strong>
          </p>
          <span data-test-id="in-car-topup-descriptionText" class="d-block description">{{'Incar_TopUp_Description' |
            translate}}</span>

          <span data-test-id="in-car-topup-disclaimerText" class="d-block description"
            [innerHTML]="'Incar_TopUp_Disclaimer' | translate" [attr.aria-label]="'Incar_TopUp_Disclaimer' | translate">
          </span>

          <div class="enable-incar-topup-checkbox">
            <label data-test-id="enable-incar-topup-input">
              {{'Enable_Incar_TopUp_Input' | translate}}
              <input type="checkbox" data-test-id="enable-incar-topup-checkbox" (change)="enableInCarTopupChange()">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <!-- Order Summary -->
        <app-product-checkout-order-card id="product-checkout-order-card" data-test-id="product-checkout-order-card">
        </app-product-checkout-order-card>
      </div>
    </div>
  </div>
</div>