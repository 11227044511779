<div class="margin-wrapper">
  <div class="container">
    <hr class="d-none d-md-block driving-connectivity-line">
    <div class="row justify-content-center">
      <div class="col-md-5">
        <img src="{{assets}}/about-cubic/about-image-car.jpg{{assetVersionNumber}}"
          class="mx-auto d-block driving-connectivity-img" data-test-id="driving-connectivity-about-image-car" />
      </div>
      <div class="col-md-7">
        <div class="driving-connectivity-text">
          <h2 class="title" data-test-id="driving-connectivity-title-text">
            {{'Driving_Connectivity_Title_Text' | translate }}
          </h2>
          <p data-test-id="driving-connectivity-description-text-1"
            [innerHTML]="'Driving_Connectivity_Description_Text_1' | translate"
            [attr.aria-label]="'Driving_Connectivity_Description_Text_1' | translate">
          </p>
          <p data-test-id="driving-connectivity-description-text-2">
            {{'Driving_Connectivity_Description_Text_2' | translate }}

          </p>
          <p data-test-id="driving-connectivity-description-text-3">
            {{'Driving_Connectivity_Description_Text_3' | translate }}
          </p>
        </div>
      </div>
    </div>
    <hr>
  </div>
</div>