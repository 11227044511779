<div class="container">
  <div class="our-believers">
    <div class="row justify-content-center">
      <div>
        <h2 class="text-center our-believers-title" data-test-id="our-believers-title-text">
          {{'Our_Partners_Title_Text' | translate }}</h2>
        <div class="our-believers-description-text">
          <p data-test-id="our-believers-description-text" class="text-center"
            [innerHTML]="'Our_Partners_Description_Text' | translate"
            [attr.aria-label]="'Our_Partners_Description_Text' | translate">
          </p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="text-center our-believers-group-logos">
        <img data-test-id="our-believers-qualcom-logo" src="{{assets}}/about-cubic/Qualcomm-Logo.png">
        <img data-test-id="our-believers-audi-rings-logo" src="{{assets}}/about-cubic/Audi-Rings.png">
        <img data-test-id="our-believers-enterprise-ireland-logo"
          src="{{assets}}/about-cubic/EnterpriseIreland-Logo.png">
        <img data-test-id="our-believers-valid-logo" src="{{assets}}/about-cubic/Valid-Logo.png">
        <img data-test-id="our-believers-isif-logo" src="{{assets}}/about-cubic/ISIF-Logo.png">
      </div>
    </div>
  </div>
</div>